import React from 'react';

import MainPage from '../MainPage';

import { AppContainer } from './style';

const App = (): JSX.Element => (
  <AppContainer>
    <MainPage />
  </AppContainer>
);

export default App;
