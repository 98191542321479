import { Language } from '../../settings/i18n/languages';
import { getDefaultLanguage } from '../../settings/i18n/i18n';

import type { languagesActionsInterfaces } from '.';
import { languagesActionTypes } from '.';

interface LanguageInitState {
  currentLanguage: Language;
}

const initState: LanguageInitState = {
  currentLanguage: getDefaultLanguage(),
};

export default function languageReducer(state = initState, action: languagesActionsInterfaces.ActionsTypes): LanguageInitState {
  switch (action.type) {
    case languagesActionTypes.SET_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload.language,
      };
    default:
      return state;
  }
}
