import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Loader } from 'react-inv-forms';

import { RootState } from '../../redux/store';
import { authActions } from '../../redux/auth';
import { languagesActions } from '../../redux/language';

import Footer from '../Footer';
import Header from '../Header';
import AuthForm from '../AuthForm';
import { LanguageSwitcher } from '../../components';

import { Language } from '../../settings/i18n/languages';
import TeacherSessionExistModal from '../modals/TeacherSessionExistModal';

import { AuthPageStyleWrapper, InfoHeader } from './style';

const { checkAuth, startSecondFactorSms, closeTeacherSessionModal, confirmSignIn } = authActions;
const { setLanguage } = languagesActions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;

interface MapDispatchToProps {
  checkAuth: any;
  setLanguage: (languageKey: Language) => void;
  startSecondFactorSms: any;
  closeTeacherSessionModal: () => void;
  confirmSignIn: () => void;
}

/*Przyjmowane właściwości
  currentLanguage - aktualny język aplikacji zadeklarowany w pliku src/traslations/languages
  checkAuth - akcja checkAuth
  startSecondFactorSms - akcja startSecondFactorSms
  level - krok
  user - informacje o użytkowniku
  loading - określa, czy aplikacja jest w stanie ładowania
  setLanguage - akcja setLanguage
*/
type IMainPage = MapDispatchToProps & MapStateToProps & WithTranslation;

class MainPage extends React.Component<IMainPage> {
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const returnUrl = searchParams.get('return_url');
    const ticket = searchParams.get('ticket');
    this.props.checkAuth({ returnUrl, ticket });
  }

  handleSecondFactorSms = (values: any) => {
    //should be AuthFormValues - to resolve
    if (values.smsCode) {
      this.props.startSecondFactorSms(values.smsCode);
    }
  };

  renderStep = () => {
    switch (this.props.level) {
      case 0:
        return <Loader format='large' />;
      case 1:
        return (
          <Form onFinish={this.handleSecondFactorSms}>
            <AuthForm loading={this.props.loading} user={this.props.user} />
          </Form>
        );
      case 2:
        return <InfoHeader>{this.props.t('SUCCESS_LOGGED_IN_WITHOUT_REDIRECT')}</InfoHeader>;
      case 3:
        return <InfoHeader>{this.props.t('AUTH_CANCELLED')}</InfoHeader>;
      default:
        break;
    }
  };

  render() {
    return (
      <AuthPageStyleWrapper>
        <aside className='login-page-background'></aside>
        <div className='login-panel'>
          <Header />
          <main>
            {this.renderStep()}
            <LanguageSwitcher setLanguage={this.props.setLanguage} currentLanguage={this.props.currentLanguage} />
          </main>
          <Footer />
        </div>
        {/* modals */}
        <TeacherSessionExistModal
          loading={this.props.sessionWarnModalLoading}
          opened={this.props.sessionWarnModalOpened}
          closeModal={this.props.closeTeacherSessionModal}
          confirmSignin={this.props.confirmSignIn}
          user={this.props.sessionWarnModalUser}
        />
      </AuthPageStyleWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  level: state.Auth.factorLevel,
  user: state.Auth.user,
  loading: state.Auth.loading,
  currentLanguage: state.Language.currentLanguage,
  sessionWarnModalOpened: state.Auth.modals.activeTeacherSessionModal.opened,
  sessionWarnModalLoading: state.Auth.modals.activeTeacherSessionModal.loading,
  sessionWarnModalUser: state.Auth.modals.activeTeacherSessionModal.superTeacher,
});

const mapDispatchToProps: MapDispatchToProps = {
  checkAuth,
  setLanguage,
  startSecondFactorSms,
  closeTeacherSessionModal,
  confirmSignIn,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MainPage) as React.ComponentType;
