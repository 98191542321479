import styled from 'styled-components';

export const StyledFooter = styled.footer`
  bottom: 0;
  left: 0;
  margin-bottom: 41px;
  width: 100%;
  position: absolute;

  @media (max-width: 768px) {
    footer {
      margin-top: 101px;
      position: static;
    }
  }
`;

export const UnorderedList = styled.ul`
  text-align: center;
  margin: 0 auto;
  padding: 0;
  list-style: none;
`;

export const Link = styled.a`
  font-size: 1.6rem;
  text-decoration: none;
  display: block;
  cursor: pointer;
  color: ${props => props.theme.palette.common.black};
  padding: 9px 0;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.palette.common.black};
  }
`;
