import { BasicStringConfig } from '../../interfaces/global';
import { pl, en } from './translations';

export enum Language {
  PL = 'pl',
  EN = 'en',
}

export const defaultLanguage = Language.PL;

export const localStorageLanguageKey = 'appLanguageAuthApp';

export type Translation = BasicStringConfig;

export interface LanguageOption {
  translations: Translation;
  localName: string;
}

export type LanguageConfig = {
  [key in Language]: LanguageOption;
};

export const languageConfig: LanguageConfig = {
  pl: {
    translations: pl,
    localName: 'Polski',
  },
  en: {
    translations: en,
    localName: 'English',
  },
};
