import { SingleErrorCodesType } from '../interfaces/errorCodes';

/* obiekt przechowujący kody służące do obsługi akjci i błędów */
export const codes: SingleErrorCodesType = {
  INVALID_TICKET: 1103,
  INVALID_SMSCODE: 2207,
  INVALID_TOKEN: 1209,
  NOT_SUFFICIENT_AUTH_TOKEN: 1113,
  INVALID_REFRESH_TOKEN: 4002,
  EXPIRED_TOKEN: 1210,
  EXPIRED_REFRESH_TOKEN: 1221,
  SECOND_FA_SMS_STARTED: 2290,
  SECOND_FA_NOT_REQUIRED: 2291,
  TEACHER_SESSION_EXIST: 2292,
};
