import React from 'react';
import i18next from 'i18next';

import storageManager from '../../helpers/storageManager';
import { Language, languageConfig, localStorageLanguageKey } from '../../settings/i18n/languages';

import { LanguageSwitcherStyleContainer } from './style';

interface ILanguageSwitcher {
  currentLanguage: Language;
  setLanguage: (languageKey: Language) => void;
}

const LanguageSwitcher = ({ currentLanguage, setLanguage }: ILanguageSwitcher): JSX.Element => {
  const handleLanguageChange = (languageKey: Language): void => {
    setLanguage(languageKey);
    i18next.changeLanguage(languageKey);
    storageManager.set(localStorageLanguageKey, languageKey);
  };

  return (
    <LanguageSwitcherStyleContainer>
      {Object.values(Language).map((language: Language) => (
        <li
          className={language === currentLanguage ? 'active' : ''}
          onClick={() => handleLanguageChange(language)}
          key={language}
        >
          {languageConfig[language].localName}
        </li>
      ))}
    </LanguageSwitcherStyleContainer>
  );
};

export default LanguageSwitcher;
