/* Adres serwera */
const apiUrl: string = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://gateway.ms.test.p.lodz.pl/';

// Domena cookies
const cookiesDomain: string = process.env.REACT_APP_COOKIES_DOMAIN_URL
  ? process.env.REACT_APP_COOKIES_DOMAIN_URL
  : '.test.p.lodz.pl';

/* Adres przekierwujący do CAS w celu uwierzytelnienia użytkownika */
const casUrl: string = process.env.REACT_APP_CAS_URL
  ? process.env.REACT_APP_CAS_URL
  : 'https://cas.test.p.lodz.pl/cas/login?service=https%3A%2F%2Fmfa.test.p.lodz.pl';

/* Adres strony głównej CAS */
const mainCasUrl: string = process.env.REACT_APP_MAIN_CAS_URL ? process.env.REACT_APP_MAIN_CAS_URL : 'https://cas.test.p.lodz.pl';

export { apiUrl, casUrl, mainCasUrl, cookiesDomain };
