import React from 'react';
//@ts-ignore -> needed for zero installs, unfortunately, project isn't ready for that
import { all, takeEvery, fork, put, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { Button } from 'react-inv-forms';
import { AnyAction } from 'redux';
import jwtDecode from 'jwt-decode';

import type { authActionsInterfaces } from '.';
import { authActions, authActionTypes } from '.';
import Auth from '../../services/auth';

import { casUrl, mainCasUrl } from '../../settings';
import { codes } from '../../settings/codes';

import { Translation } from '../../components';

import StorageManager from '../../helpers/storageManager';
import locationManager from '../../helpers/locationManager';

const { setFactorLevel, executeErrorCode, openTeacherSessionModal } = authActions;

export function* checkAuthWatcher() {
  yield takeEvery(authActionTypes.CHECK_AUTH, checkAuth);
}
export function* checkAuth({ payload }: authActionsInterfaces.CheckAuth) {
  const { ticket, returnUrl } = payload;
  const authRecurrence = StorageManager.get('pl_auth_rec');
  if (authRecurrence && Number(authRecurrence) > 5) {
    yield put({
      type: authActionTypes.CANCEL_AUTH,
    });
  } else {
    const whichTicketCheck = authRecurrence ? Number(authRecurrence) + 1 : 1;
    StorageManager.set('pl_auth_rec', whichTicketCheck.toString(), true);
    if (returnUrl) {
      StorageManager.set('return_url', returnUrl, false, true);
    }
    const idToken = StorageManager.get('id_token');
    if (idToken) {
      // jeśli w Cookies istnieje token, wywołaj akcję CHECK_TOKEN
      yield put({
        type: authActionTypes.CHECK_TOKEN,
      });
    } else {
      /*     jeśli token nie istnieje w Cookies,
            lecz jest dostępny ticket w URL, wywołaj akcję CHECK_TICKET */
      if (ticket) {
        yield put({
          type: authActionTypes.CHECK_TICKET,
          payload: { ticket },
        });
      } else {
        //jeśli nie istnieje ticket ani token wywołaj akcję REDIRECT_TO_CAS
        yield put({
          type: authActionTypes.REDIRECT_TO_CAS,
        });
      }
    }
  }
}

export function* checkTokenWatcher() {
  yield takeEvery(authActionTypes.CHECK_TOKEN, checkToken);
}
export function* checkToken() {
  //w celu sprawdzenia ważności tokenu wywołaj akcję WHO_I_AM_STARTED
  yield put({
    type: authActionTypes.WHO_I_AM_STARTED,
  });
}

export function* whoIAmWatcher() {
  yield takeEvery(authActionTypes.WHO_I_AM_STARTED, whoIAm);
}
export function* whoIAm() {
  try {
    // wyślij zapytanie o dane użytkownika
    yield Auth.whoIAm();
    // w przypadku braku zwróconego błędu ustaw poziom (krok) na 2
    yield put(setFactorLevel(2));
    yield put({
      type: authActionTypes.WHO_I_AM_SUCCESS,
    });
    //przekieruj do adresu z którego został przekierowany user
    yield put({
      type: authActionTypes.REDIRECT_TO_RETURN_URL,
    });
    // }
  } catch (error) {
    if (error && error.errorCode) {
      //jeśli istnieje errorCode wywołaj kreatora akcji executeErrorCode z podanym kodem błędu
      yield put(executeErrorCode(error.errorCode));
    }
    yield put({
      type: authActionTypes.WHO_I_AM_FAIL,
    });
  }
}

export function* refreshTokenWatcher() {
  yield takeEvery(authActionTypes.REFRESH_TOKEN_STARTED, refreshToken);
}
export function* refreshToken() {
  try {
    const refreshToken = StorageManager.get('refresh_token');
    if (refreshToken) {
      //wyślij zapytanie o odświeżenie tokenu
      const response = yield Auth.refreshToken(refreshToken);
      //w razie sukcesu zapisz token i refresh token do localStorage
      StorageManager.set('id_token', response.token, true);
      StorageManager.set('refresh_token', response.refreshToken, true);
      yield put({
        type: authActionTypes.REFRESH_TOKEN_SUCCESS,
      });
    } else {
      throw new Error('Missing refresh token');
    }
  } catch (error) {
    //w razie błędu usuń token i refresh token z cookies jeśli w nim istnieją
    StorageManager.remove('id_token');
    StorageManager.remove('refresh_token');
    yield put({
      type: authActionTypes.REFRESH_TOKEN_FAIL,
    });
    //przekieruj z powrotem do CAS w celu uwierzytelnienia
    yield put({
      type: authActionTypes.REDIRECT_TO_CAS,
    });
  }
}

export function* checkTicketWatcher() {
  yield takeEvery(authActionTypes.CHECK_TICKET, checkTicket);
}
export function* checkTicket({ payload }: AnyAction) {
  try {
    const { ticket } = payload;

    //wyślij zapytanie z prośbą o weryfikację ticketu
    const response = yield call(Auth.checkTicket, ticket);
    // const whichTicketCheck = authRecurrence ? Number(authRecurrence) + 1 : 0;
    // StorageManager.set('pl_auth_rec', whichTicketCheck.toString(), true);
    if (response && response.data.token && response.data.refreshToken) {
      //jeśli nie wystąpił błąd oraz zostały zwrócone token i refresh token zapisz je do localStorage
      StorageManager.set('id_token', response.data.token, true);
      StorageManager.set('refresh_token', response.data.refreshToken, true);
      //zdekoduj token jwt
      const { user } = jwtDecode(response.data.token);
      //wywołaj akcję SET_USER przekazując imie i nazwisko użytkownika ze zdekodowanego tokenu jwt
      yield put({
        type: authActionTypes.SET_USER,
        payload: { firstName: user.firstName, lastName: user.lastName },
      });
    }
    if (response && response.data && response.data.actionCode) {
      /*    jeśli w odpowiedzi z serwera istnieje actionCode,
          wywołaj akcję EXECUTE_ACTION_CODE przekazując go do niej */
      const data = { code: response.data.actionCode, ...response.data };
      yield put({
        type: authActionTypes.EXECUTE_ACTION_CODE,
        payload: data,
      });
    }
    // }
  } catch (error) {
    if (error && error.errorCode) {
      //w razie błędu wywołaj kreatora akcji executeErrorCode przekazując do niego errorCode
      yield put(executeErrorCode(error.errorCode));
    }
  }
}

export function* startSecondFactorSmsWatcher() {
  yield takeEvery(authActionTypes.SECOND_FACTOR_SMS_STARTED, startSecondFactorSms);
}
export function* startSecondFactorSms({ payload }: authActionsInterfaces.StartSecondFactorSms) {
  try {
    const { smsCode } = payload;
    //wyślij zapytanie z prośbą o weryfikację kodu sms
    const response = yield Auth.startSecondFactorSms(smsCode);
    if (response && response.token && response.refreshToken) {
      yield put({
        type: authActionTypes.SECOND_FACTOR_SMS_SUCCESS,
      });
      /*       w przypadku braku błędu, jesli otrzymano token i refreshToken
            wywołaj akcję SET_FACTOR_LEVEL ustawiającą poziom na 2 */
      yield put({
        type: authActionTypes.SET_FACTOR_LEVEL,
        payload: { newFactorLevel: 2 },
      });
      //oraz zapisz token i refresh token do localStorage
      StorageManager.set('id_token', response.token, true);
      StorageManager.set('refresh_token', response.refreshToken, true);
      //następnie wywołaj akcję REDIRECT_TO_RETURN_URL
      yield put({
        type: authActionTypes.REDIRECT_TO_RETURN_URL,
      });
    }
  } catch (error) {
    if (error && error.errorCode) {
      //w razie błędu wywołaj kreatora akcji executeErrorCode przekazując do niego errorCode
      yield put({
        type: authActionTypes.SECOND_FACTOR_SMS_FAIL,
      });
      yield put(executeErrorCode(error.errorCode));
    }
  }
}

export function* executeActionCodesWatcher() {
  yield takeEvery(authActionTypes.EXECUTE_ACTION_CODE, executeActionCodes);
}
export function* executeActionCodes({ payload }: AnyAction) {
  const code = Number(payload.code);
  switch (code) {
    case codes.TEACHER_SESSION_EXIST:
      // aktualnie istnieje aktywna sesja nauczyciela
      StorageManager.set('id_token', payload.token, true);
      StorageManager.set('refresh_token', payload.refreshToken, true);
      //wyświetl okno z zapytaniem czy wylogować
      const config: authActionsInterfaces.UserNames = {
        firstName: payload.parent_firstname,
        lastName: payload.parent_lastname,
      };
      yield put(openTeacherSessionModal(config));
      break;
    case codes.SECOND_FA_NOT_REQUIRED:
      // 2FA nie jest wymagany, przekieruj użytkownika do aplikacji docelowej
      yield put({
        type: authActionTypes.SET_FACTOR_LEVEL,
        payload: { newFactorLevel: 2 },
      });
      yield put({
        type: authActionTypes.REDIRECT_TO_RETURN_URL,
      });
      break;
    case codes.SECOND_FA_SMS_STARTED:
      /*       w razie informacji, że wysłano sms do użytkownika
            ustaw level (krok) na 1 w celu wyświetlenia odpowiedniego widoku użytkownikowi */
      yield put({
        type: authActionTypes.SET_FACTOR_LEVEL,
        payload: { newFactorLevel: 1 },
      });
      break;
    default:
      break;
  }
}

export function* executeErrorCodesWatcher() {
  yield takeEvery(authActionTypes.EXECUTE_ERROR_CODE, executeErrorCodes);
}
export function* executeErrorCodes({ payload }: authActionsInterfaces.ExecuteErrorCode) {
  const code = Number(payload.code);

  switch (code) {
    case codes.INVALID_SMSCODE:
      //nic nie rób, obsługa wyświetlenia wiadomości dokonana w superFetch
      break;
    case codes.INVALID_TICKET:
      /*       w razie informacji, że ticket jest niepoprawny
            przekieruj do CAS w celu ponownego uwierzytenlnienia */
      yield put({
        type: authActionTypes.REDIRECT_TO_CAS,
      });
      break;
    case codes.EXPIRED_TOKEN:
      //jeśli token wygasł rozpocznij procedurę odświeżania
      yield put({
        type: authActionTypes.REFRESH_TOKEN_STARTED,
      });
      break;
    case codes.MISSING_2FA:
      /*       jeśli token nie jest podpisany 2FA usuń token i refresh token z localStorage i przekieruj
             do CAS w celu ponownego uwierzytenlnienia */
      StorageManager.remove('id_token');
      StorageManager.remove('refresh_token');
      yield put({
        type: authActionTypes.REDIRECT_TO_CAS,
      });
      break;
    case codes.MISSING_PHONE_NUMBER_IN_CAS:
      /*       w przypadku kodu informującego o braku numeru telefonu w CAS wyświetl
            notyfikację z takim komunikatem z udziałem komponentu Translation ( tłumaczenie ) */
      const btn: any = (
        <Button
          onClick={() => {
            locationManager.replace(mainCasUrl);
          }}
        >
          <Translation id='BACK_TO_CASS_NOTIFICATION_BUTTON' />
        </Button>
      );
      notification.error({
        message: <Translation id='MISSING_PHONE_CASS_NOTIFICATION' />,
        btn,
        duration: 0,
      });
      break;
    default:
      //w przypadku nieobsługiwanego błędu przekieruj do CAS w celu ponownego uwierzytelnienia
      yield put({
        type: authActionTypes.REDIRECT_TO_CAS,
      });
      break;
  }
}

export function* redirectToCasWatcher() {
  yield takeEvery(authActionTypes.REDIRECT_TO_CAS, redirectToCas);
}
export function* redirectToCas() {
  //usuń token
  StorageManager.remove('id_token');
  StorageManager.remove('refresh_token');
  //przekieruj na adres CAS
  yield locationManager.replace(casUrl);
}

export function* redirectToReturnUrlWatcher() {
  yield takeEvery(authActionTypes.REDIRECT_TO_RETURN_URL, redirectToReturnUrl);
}
export function* redirectToReturnUrl() {
  const returnUrl = StorageManager.get('return_url');
  if (returnUrl) {
    //jeśli takowy istnieje, przekieruj na adres pobrany z localStorage
    yield StorageManager.remove('return_url');
    yield locationManager.replace(returnUrl);
    yield StorageManager.remove('pl_auth_rec');
  }
}

export function* confirmSignInWatcher() {
  yield takeEvery(authActionTypes.CONFIRM_SIGNIN_START, confirmSignIn);
}
export function* confirmSignIn() {
  //potwierdzenie chęci zalogowania mimo aktywnej sesji Super Nauczyciela
  try {
    const response = yield Auth.confirmSignIn();
    StorageManager.set('id_token', response.data.token, true);
    StorageManager.set('refresh_token', response.data.refreshToken, true);
    yield put({
      type: authActionTypes.CONFIRM_SIGNIN_SUCCESS,
    });
    yield put({
      type: authActionTypes.SET_FACTOR_LEVEL,
      payload: { newFactorLevel: 2 },
    });
    yield put({
      type: authActionTypes.REDIRECT_TO_RETURN_URL,
    });
  } catch (error) {
    yield put({
      type: authActionTypes.CONFIRM_SIGNIN_FAIL,
    });
    if (error && error.errorCode) {
      //w razie błędu wywołaj kreatora akcji executeErrorCode przekazując do niego errorCode
      yield put(executeErrorCode(error.errorCode));
    }
  }
}
export function* cancelAuthWatcher() {
  yield takeEvery(authActionTypes.CANCEL_AUTH, cancelAuth);
}
export function* cancelAuth() {
  // anulowanie dalszego procesu autoryzacji
  StorageManager.clear();
  StorageManager.remove('pl_auth_rec', true);
  yield put({
    type: authActionTypes.SET_FACTOR_LEVEL,
    payload: { newFactorLevel: 3 },
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthWatcher),
    fork(whoIAmWatcher),
    fork(checkTicketWatcher),
    fork(checkTokenWatcher),
    fork(executeActionCodesWatcher),
    fork(executeErrorCodesWatcher),
    fork(redirectToCasWatcher),
    fork(redirectToReturnUrlWatcher),
    fork(startSecondFactorSmsWatcher),
    fork(refreshTokenWatcher),
    fork(confirmSignInWatcher),
    fork(cancelAuthWatcher),
  ]);
}
