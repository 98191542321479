import styled from 'styled-components';

export const LanguageSwitcherStyleContainer = styled.ul`
  font-weight: 400;
  font-size: 1.6rem;
  padding: 0;
  display: flex;
  justify-content: space-around;
  list-style: none;
  width: 100%;

  li {
    display: inline-block;
    opacity: 0.5;
    text-decoration: none;
    color: ${props => props.theme.palette.common.black};
    cursor: pointer;
    &.active {
      font-weight: 700;
    }
  }
`;
