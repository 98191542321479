import React from 'react';
import { Button, Form, Input } from 'react-inv-forms';
import { useTranslation } from 'react-i18next';

import { AuthFormHeader } from './style';
interface IAuthForm {
  user: any;
  loading: boolean;
}

/* Komponent renderujący formularz do podania danych logowania */
const AuthForm = ({ user, loading }: IAuthForm) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Form.Item>
        <AuthFormHeader>{t('AUTH_FORM_HEADER')}</AuthFormHeader>
      </Form.Item>
      <Form.Item>
        {user && user.firstName && user.lastName ? t('AUTH_FORM_LOGIN_AS') : null}
        <strong>{user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : null}</strong>
      </Form.Item>
      <Form.Item
        name='smsCode'
        rules={[
          {
            required: true,
            message: t('AUTH_FORM_PROVIDE_SMS'),
          },
        ]}
      >
        <Input placeholder={t('AUTH_FORM_PROVIDE_SMS')} />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} htmlType='submit'>
          {t('AUTH_FORM_BUTTON_TEXT')}
        </Button>
      </Form.Item>
    </React.Fragment>
  );
};

export default AuthForm;
