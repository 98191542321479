import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import storageManager from '../../helpers/storageManager';

import { en, pl } from './translations';
import { defaultLanguage, localStorageLanguageKey, Language } from './languages';

export const getDefaultLanguage = (): Language => {
  const languageFromLocalStorage = storageManager.get(localStorageLanguageKey);
  let languageToReturn = defaultLanguage;

  Object.values(Language).forEach((language: Language) => {
    if (language === languageFromLocalStorage) {
      languageToReturn = language;
    }
  });

  return languageToReturn;
};

const customResources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: customResources,
    lng: getDefaultLanguage(),
    fallbackLng: 'pl',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    },
  });
