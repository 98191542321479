import { AnyAction } from 'redux';
import type { authActionsInterfaces } from '.';
import { authActionTypes } from '.';

interface AuthState {
  loading: boolean;
  ticket: string | null;
  factorLevel: number;
  user: authActionsInterfaces.UserNames;
  modals: {
    activeTeacherSessionModal: {
      loading: boolean;
      opened: boolean;
      superTeacher: authActionsInterfaces.UserNames;
    };
  };
}

const initState: AuthState = {
  loading: false,
  ticket: null,
  factorLevel: 0,
  user: {
    firstName: null,
    lastName: null,
  },
  modals: {
    activeTeacherSessionModal: {
      opened: false,
      loading: false,
      superTeacher: {
        firstName: null,
        lastName: null,
      },
    },
  },
};

export default function authReducer(state = initState, action: AnyAction): AuthState {
  switch (action.type) {
    case authActionTypes.CHECK_AUTH:
      //zapisuję stan ładowania oraz ticket
      return {
        ...state,
        loading: true,
        ticket: action.payload.ticket ? action.payload.ticket : null,
      };
    case authActionTypes.CONFIRM_SIGNIN_START:
      //rozpoczynam procedurę logowania
      return {
        ...state,
        modals: {
          ...state.modals,
          activeTeacherSessionModal: {
            ...state.modals.activeTeacherSessionModal,
            loading: true,
          },
        },
      };
    case authActionTypes.CONFIRM_SIGNIN_SUCCESS:
    case authActionTypes.CONFIRM_SIGNIN_FAIL:
      //rozpoczynam procedurę logowania
      return {
        ...state,
        modals: {
          ...state.modals,
          activeTeacherSessionModal: {
            ...state.modals.activeTeacherSessionModal,
            loading: false,
          },
        },
      };
    case authActionTypes.OPEN_ACTIVE_SESSION_TEACHER_MODAL:
      // otwieram modal
      return {
        ...state,
        modals: {
          ...state.modals,
          activeTeacherSessionModal: {
            ...state.modals.activeTeacherSessionModal,
            opened: true,
            superTeacher: action.payload,
          },
        },
      };
    case authActionTypes.CLOSE_ACTIVE_SESSION_TEACHER_MODAL:
      //zamykam modal
      return {
        ...state,
        modals: {
          ...state.modals,
          activeTeacherSessionModal: {
            ...state.modals.activeTeacherSessionModal,
            opened: false,
            superTeacher: {
              firstName: null,
              lastName: null,
            },
          },
        },
      };
    case authActionTypes.SET_FACTOR_LEVEL:
      //zapisuje factor level ( potrzebny do wyświetlenia odpowiedniego widoku )
      return {
        ...state,
        loading: false,
        factorLevel: action.payload.newFactorLevel,
      };
    case authActionTypes.RESET_FACTOR_LEVEL:
      //przywrócenie stanu ładowania oraz factorLevel do stanu początkowego
      return {
        ...state,
        loading: false,
        factorLevel: initState.factorLevel,
      };
    case authActionTypes.SECOND_FACTOR_SMS_STARTED:
      //zmiana stanu ładowania na true
      return {
        ...state,
        loading: true,
      };
    case authActionTypes.SECOND_FACTOR_SMS_SUCCESS:
      //zmiana stanu ładowania na false
      return {
        ...state,
        loading: false,
      };
    case authActionTypes.SECOND_FACTOR_SMS_FAIL:
      //zmiana stanu ładowania na false
      return {
        ...state,
        loading: false,
      };
    case authActionTypes.SET_USER:
      //uzupełnienie danych użytkownika
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      };
    case authActionTypes.RESET_USER:
      //reset danych użytkownika
      return {
        ...state,
        user: {
          ...initState.user,
        },
      };
    default:
      return state;
  }
}
