import { Translation } from '../../../settings/i18n/languages';

/* Obiekt przechowujący polskie tłumaczenia, każde tłumaczenie ma swój unikalny klucz, po którym jest wywoływane */
export const pl: Translation = {
  YES: 'Tak',
  NO: 'Nie',
  ID: 'Id',
  HEADER_TITLE: 'Centralny System Uwierzytelniania',
  AUTH_FORM_HEADER: 'Logowanie - Krok 2',
  AUTH_FORM_LOGIN_AS: 'Logowanie jako: ',
  AUTH_FORM_PROVIDE_SMS: 'Wprowadź kod SMS',
  AUTH_FORM_SMS_LABEL: 'Kod SMS',
  AUTH_FORM_INCORRECT_SMS: 'Kod SMS jest nieprawidłowy',
  AUTH_FORM_BUTTON_TEXT: 'Dalej',
  FOOTER_LINK_DATA_PROTECTION: 'Ochrona Danych Osobowych',
  FOOTER_LINK_COMP_CENTER: 'Uczelniane Centrum Informatyczne',
  SUCCESS_LOGGED_IN_WITHOUT_REDIRECT: 'Zalogowano pomyślnie, teraz możesz przejść do dowolnego serwisu.',
  AUTH_CANCELLED: 'Wystąpił błąd procesu autoryzacji. Logowanie zostało przerwane. Otwórz aplikację w trybie incognito.',
  BACK_TO_CASS_NOTIFICATION_BUTTON: 'Wróć do CAS',
  MISSING_PHONE_CASS_NOTIFICATION: 'Nie podanu numeru telefeonu w serwisie CAS',
  AUTH_REQUIRED_TITLE: 'Wymagane uwierzytelnienie',
  AUTH_REQUIRED_BODY: 'Zostaniesz przekierowany do serwisu CAS w celu pobrania nowego ticketu',

  //Tłumaczenia dotyczące modala informacji o aktywnej sesji
  TEACHER_SESSION_ACTIVE_MODAL_TITLE: 'Wykryto aktywną sesję Super Nauczyciela!',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_1: 'Na Twoim koncie aktualnie zalogowany jest',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_2: 'jako Super Nauczyciel.',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_3: 'Czy chcesz się zalogować wylogowując Super Nauczyciela ze swojego konta?',
};
