//Komponent oparty na styled-components dostarczający stylowanie dla AuthPage

import styled from 'styled-components';

import background from '../../images/pl-background.png';

interface IAuthPageStyleWrapper {
  children: React.ReactNode;
}

export const AuthPageStyleWrapper = styled.div<IAuthPageStyleWrapper>`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;

  .login-panel,
  .login-page-background {
    margin: 0;
    height: 100vh;
  }

  .login-page-background {
    background: url(${background}) no-repeat center;
    background-size: cover;
    width: calc(100vw - 500px);
  }

  .login-panel {
    padding: 0 50px;
    width: 500px;
    position: relative;
    min-height: 768px;
  }

  .login-panel {
    padding: 0 50px;
    width: 500px;
    position: relative;
    min-height: 768px;
  }

  @media (max-width: 768px) {
    .login-page-background {
      display: none;
    }

    .login-panel {
      padding: 0 16px;
      margin: 0 auto;
    }
  }

  @media (max-height: 768px) {
    .login-page-background {
      height: 100%;
      min-height: 768px;
    }
  }
`;

export const InfoHeader = styled.h2`
  color: ${props => props.theme.palette.common.blackWithOpacity};
  font-size: 2.4rem;
  text-align: center;
  padding-bottom: 50px;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;
