import Cookies from 'js-cookie';
import { cookiesAttributes } from '../settings/cookiesAttributes';

interface IStorageManager {
  set: (key: string, value: string, setToCookie?: boolean, setToLocalStorage?: boolean) => void;
  get: (key: string) => string | null;
  remove: (key: string, removeFromCookie?: boolean) => void;
  clear: () => void;
}

/** Utils służący do operacji na local storage i cookies */
const StorageManager: IStorageManager = {
  set: (key, value, setToCookie = false, setToLocalStorage = false) => {
    if (setToLocalStorage) {
      localStorage.setItem(key, value);
    }
    if (setToCookie) {
      Cookies.set(key, value, cookiesAttributes);
    }
  },

  get: key => {
    const localStorageValue = localStorage.getItem(key);
    const value = localStorageValue ? localStorageValue : Cookies.get(key);
    return value ? value : null;
  },

  remove: (key, removeFromCookie = true) => {
    localStorage.removeItem(key);

    if (removeFromCookie) {
      Cookies.remove(key, cookiesAttributes);
    }
  },

  clear: () => {
    localStorage.clear();
    Cookies.remove('id_token', cookiesAttributes);
    Cookies.remove('refresh_token', cookiesAttributes);
  },
};

export default StorageManager;
