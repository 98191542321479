import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import App from './containers/App';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';

import GlobalStyle from './settings/styles/globalStyle';
import { themeDefault } from './settings/theme';
import '@openfonts/open-sans_all';

import './settings/i18n/i18n';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themeDefault}>
      <App />
      <GlobalStyle />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
