interface ILocationManager {
  replace: (url: string) => void;
}

/** Utils służący zmianie urla w przeglądarce */
const LocationManager: ILocationManager = {
  replace: (url: string) => {
    window.location.replace(url);
  },
};

export default LocationManager;
