import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITranslation {
  id: string;
}

const Translation = ({ id }: ITranslation): JSX.Element => {
  const { t } = useTranslation();

  return <>{t(id)}</>;
};

export default Translation;
