import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-inv-forms';

import locationManager from '../../helpers/locationManager';
import { mainCasUrl } from '../../settings';
import { UserNames } from '../../redux/auth/interfaces';

interface ITeacherSessionExistModal {
  opened: boolean;
  loading: boolean;
  closeModal: () => void;
  confirmSignin: () => void;
  user: UserNames;
}

/* Modal pojawiający się w momencie próby zalogowania na konto, na którym obecnie znajduje się Supernauczyciel */
const TeacherSessionExistModal = ({
  opened,
  loading,
  closeModal,
  confirmSignin,
  user,
}: ITeacherSessionExistModal): JSX.Element => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    confirmSignin();
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    locationManager.replace(mainCasUrl);
  };

  return (
    <Modal
      visible={opened}
      okText={t('YES')}
      cancelText={t('NO')}
      confirmLoading={loading}
      onCancel={handleCancel}
      onOk={handleConfirm}
      title={t('TEACHER_SESSION_ACTIVE_MODAL_TITLE')}
    >
      <p>
        {t('TEACHER_SESSION_ACTIVE_MODAL_BODY_1')}
        <span style={{ fontWeight: 'bold' }}>{` ${user.firstName ? user.firstName : ''} ${
          user.lastName ? user.lastName : ''
        } `}</span>
        {t('TEACHER_SESSION_ACTIVE_MODAL_BODY_2')}
      </p>
      <p>{t('TEACHER_SESSION_ACTIVE_MODAL_BODY_3')}</p>
    </Modal>
  );
};

export default TeacherSessionExistModal;
