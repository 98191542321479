import { message } from 'antd';

import { apiUrl } from '../settings';
import { errorCodes } from '../settings/i18n/translations/errorCodes';
import { getDefaultLanguage } from '../settings/i18n/i18n';

import StorageManager from '../helpers/storageManager';

import { CustomHeader, SuperFetchType } from '../interfaces/superFetch';

/* Budowa stosownego nagłówka do zapytań API */
export const customHeader = (): CustomHeader => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${StorageManager.get('id_token')}`,
  Lang: getDefaultLanguage(),
});

/* Obsługa zapytań do API i otrzymanie odpowiedzi */
const base = (method: string, url: string, data: object | undefined = {}): Promise<Response> => {
  return fetch(`${apiUrl}${url}`, {
    method,
    headers: { ...customHeader() },
    body: method === 'post' || method === 'put' ? JSON.stringify(data) : null,
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'success') {
        return response;
      } else {
        throw response;
      }
    })
    .catch(response => {
      const { errorCode } = response;
      if (errorCode) {
        if (errorCodes[getDefaultLanguage()][errorCode]) {
          message.error(errorCodes[getDefaultLanguage()][errorCode]);
        }
      } else {
        message.error(errorCodes[getDefaultLanguage()]['unknown']);
      }
      throw response;
    });
};

/* Przypisanie rodzajów zapytań do metody obsługującej zapytania */
const SuperFetch: SuperFetchType = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
