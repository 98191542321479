import { Translation } from '../../../settings/i18n/languages';

/* Obiekt przechowujący angleiskie tłumaczenia, każde tłumaczenie ma swój unikalny klucz, po którym jest wywoływane */
export const en: Translation = {
  YES: 'Yes',
  NO: 'No',
  ID: 'Id',
  HEADER_TITLE: 'Central Authentication System',
  AUTH_FORM_HEADER: 'Login - Step 2',
  AUTH_FORM_LOGIN_AS: 'Login as: ',
  AUTH_FORM_PROVIDE_SMS: 'Insert SMS code',
  AUTH_FORM_SMS_LABEL: 'SMS Code',
  AUTH_FORM_INCORRECT_SMS: 'SMS code is incorrect',
  AUTH_FORM_BUTTON_TEXT: 'Next',
  FOOTER_LINK_DATA_PROTECTION: 'Personal Data Protection',
  FOOTER_LINK_COMP_CENTER: 'Uczelniane Centrum Informatyczne Lodz University of Technology',
  SUCCESS_LOGGED_IN_WITHOUT_REDIRECT: 'Signed in successfully, you can go to any service.',
  AUTH_CANCELLED: 'Authorization process error. Login process has been cancelled. Open application in private mode.',
  BACK_TO_CASS_NOTIFICATION_BUTTON: 'Back to CAS',
  MISSING_PHONE_CASS_NOTIFICATION: 'Missing phone number in CAS service',
  AUTH_REQUIRED_TITLE: 'Authorization error',
  AUTH_REQUIRED_BODY: 'You will redirect to CAS service to get new ticket',

  //Tłumaczenia dotyczące modala informacji o aktywnej sesji
  TEACHER_SESSION_ACTIVE_MODAL_TITLE: 'Detected Super Teacher active session!',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_1: ' is currently logged in on your account',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_2: 'as Super Teacher.',
  TEACHER_SESSION_ACTIVE_MODAL_BODY_3: 'Would you like to log in and log him out of your account?',
};
