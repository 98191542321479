import styled from 'styled-components';

interface IAuthFormHeader {
  children: React.ReactNode;
}

export const AuthFormHeader = styled.h2<IAuthFormHeader>`
  font-weight: 700;
  font-size: 2.4rem;
  text-align: left;
  margin-bottom: 34px;
`;
