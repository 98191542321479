export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const CHECK_TICKET = 'CHECK_TICKET';

export const EXECUTE_ACTION_CODE = 'EXECUTE_ACTION_CODE';
export const EXECUTE_ERROR_CODE = 'EXECUTE_ERROR_CODE';

export const SET_FACTOR_LEVEL = 'SET_FACTOR_LEVEL';
export const RESET_FACTOR_LEVEL = 'RESET_FACTOR_LEVEL';

export const SECOND_FACTOR_SMS_STARTED = 'SECOND_FACTOR_SMS_STARTED';
export const SECOND_FACTOR_SMS_SUCCESS = 'SECOND_FACTOR_SMS_SUCCESS';
export const SECOND_FACTOR_SMS_FAIL = 'SECOND_FACTOR_SMS_FAIL';

export const WHO_I_AM_STARTED = 'WHO_I_AM_STARTED';
export const WHO_I_AM_SUCCESS = 'WHO_I_AM_SUCCESS';
export const WHO_I_AM_FAIL = 'WHO_I_AM_FAIL';

export const REFRESH_TOKEN_STARTED = 'REFRESH_TOKEN_STARTED';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const REDIRECT_TO_CAS = 'REDIRECT_TO_CAS';
export const REDIRECT_TO_RETURN_URL = 'REDIRECT_TO_RETURN_URL';

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

export const OPEN_ACTIVE_SESSION_TEACHER_MODAL = 'OPEN_ACTIVE_SESSION_TEACHER_MODAL';
export const CLOSE_ACTIVE_SESSION_TEACHER_MODAL = 'CLOSE_ACTIVE_SESSION_TEACHER_MODAL';

export const CONFIRM_SIGNIN_START = 'CONFIRM_SIGNIN_START';
export const CONFIRM_SIGNIN_SUCCESS = 'CONFIRM_SIGNIN_SUCCESS';
export const CONFIRM_SIGNIN_FAIL = 'CONFIRM_SIGNIN_FAIL';

export const CANCEL_AUTH = 'CANCEL_AUTH';
