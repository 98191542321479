import { ErrorCodesType } from '../../../interfaces/errorCodes';

/* Obiekt przechowujący tłumaczenia błędów ogólnych wyświetlanych użytkownikowi za pomocą helpera superFetch */
export const errorCodes: ErrorCodesType = {
  en: {
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    2207: 'Invalid sms code',
    unknown: 'Unknown error',
  },
  pl: {
    401: 'Brak autoryzacji',
    403: 'Brak dostępu',
    404: 'Strony nie znaleziono lub nie istnieje',
    500: 'Wewnętrzny błąd serwera',
    502: 'Bad Gateway',
    503: 'Serwis niedostępny',
    504: 'Gateway Timeout',
    2207: 'Niepoprawny kod sms',
    unknown: 'Wystąpił nieznany błąd',
  },
};
