import type { authActionsInterfaces } from '.';
import { authActionTypes } from '.';

export const checkAuth = (data: authActionsInterfaces.CheckAuthData) =>
  ({
    type: authActionTypes.CHECK_AUTH,
    payload: data,
  } as const);

export const startSecondFactorSms = (smsCode: string) =>
  ({
    type: authActionTypes.SECOND_FACTOR_SMS_STARTED,
    payload: { smsCode },
  } as const);

export const setFactorLevel = (level: number) =>
  ({
    type: authActionTypes.SET_FACTOR_LEVEL,
    payload: { newFactorLevel: level },
  } as const);

export const executeErrorCode = (errorCode: number) =>
  ({
    type: authActionTypes.EXECUTE_ERROR_CODE,
    payload: { code: errorCode },
  } as const);

export const openTeacherSessionModal = (payload: authActionsInterfaces.UserNames) =>
  ({
    type: authActionTypes.OPEN_ACTIVE_SESSION_TEACHER_MODAL,
    payload,
  } as const);

export const closeTeacherSessionModal = () =>
  ({
    type: authActionTypes.CLOSE_ACTIVE_SESSION_TEACHER_MODAL,
  } as const);

export const confirmSignIn = () =>
  ({
    type: authActionTypes.CONFIRM_SIGNIN_START,
  } as const);
