import { DefaultTheme } from 'styled-components';

export const themeDefault: DefaultTheme = {
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
      blackWithOpacity: '#000000D9',
    },
    primary: {
      main: '#8B231D',
      fontColor: '#ffffff',
    },
    border: '#E9E9E9',
  },
  font: {
    fontSize: '10px',
    fontFamily: '"Open Sans", sans-serif',
  },
};
