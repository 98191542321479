import SuperFetch from '../helpers/superFetch';

type RequestWithStringParam = (param: string) => Promise<Response>;
type RequestWithNoParam = () => Promise<Response>;

interface IAuth {
  whoIAm: RequestWithNoParam;
  refreshToken: RequestWithStringParam;
  checkTicket: RequestWithStringParam;
  confirmSignIn: RequestWithNoParam;
  startSecondFactorSms: RequestWithStringParam;
}

/** Serwis zawierające zapytania autoryzacyjne do API */
const Auth: IAuth = {
  /** Pobiranie danych użytkownika */
  whoIAm: () => SuperFetch.get('user/who-am-i'),

  /** Odnowienie tokenu */
  refreshToken: refreshToken => SuperFetch.post('auth/refresh', { refreshToken }),

  /** Weryfikacja biletu otrzymanego z CAS */
  checkTicket: ticket => SuperFetch.get(`cas/sso?ticket=${ticket}`),

  /** Nadpisanie tokenu zalogowane supernauczyciela */
  confirmSignIn: () => SuperFetch.post('jwt/override-super-user-token'),

  /** Weryfikacja kodu otrzymanego w smsie */
  startSecondFactorSms: smsCode => SuperFetch.get(`sms/check?token=${smsCode}`),
};

export default Auth;
