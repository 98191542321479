import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderContainer, HeaderText } from './style';

import logo from '../../images/logo.svg';

const Header = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <img alt='logo' src={logo} />
      <HeaderText>{t('HEADER_TITLE')}</HeaderText>
    </HeaderContainer>
  );
};

export default Header;
