import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledFooter, Link, UnorderedList } from './style';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <UnorderedList>
        <li>
          <Link href='#'>{t('FOOTER_LINK_DATA_PROTECTION')}</Link>
        </li>
        <li>
          <Link href='#'>{t('FOOTER_LINK_COMP_CENTER')}</Link>
        </li>
      </UnorderedList>
    </StyledFooter>
  );
};

export default Footer;
