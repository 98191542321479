import styled from 'styled-components';

interface IHeaderContainer {
  children: React.ReactNode;
}

export const HeaderContainer = styled.header<IHeaderContainer>`
  img {
    vertical-align: middle;
    border-style: none;
  }
`;

interface IHeaderText {
  children: React.ReactNode;
}

export const HeaderText = styled.h1<IHeaderText>`
  color: ${props => props.theme.palette.common.blackWithOpacity};
  font-size: 1.6rem;
  text-align: center;
  font-weight: 400;
  margin: 50px 0;
`;
